import '../styles/Clients.scss';

import React from 'react';
import { FormattedMessage } from 'react-intl';
import Banner from '../components/Banner';

import client1 from '../assets/images/client-grab.png';
import client2 from '../assets/images/client-uex.png';
import client3 from '../assets/images/client-lutheran.png';
import client4 from '../assets/images/client-bnp.png';
import client5 from '../assets/images/client-rainbow.png';
import client6 from '../assets/images/client-frontier.png';
import client7 from '../assets/images/client-mercuri.png';
import client8 from '../assets/images/client-avanto.png';
import client9 from '../assets/images/client-aws.png';
import client10 from '../assets/images/client-ftt.png';
import client11 from '../assets/images/client-aims.png';
import client12 from '../assets/images/client-celiveo.png';
import client13 from '../assets/images/client-capelle-blue.png';

const clientList = [
	client1,
	client2,
	client3,
	client4,
	client5,
	client6,
	client7,
	client8,
	client9,
	client10,
	client11,
	client12,
	client13
];

function ClientsComponent() {
	const clientListDom = clientList.map(client => (
		<div key={client} className="col-6 col-md-3">
			<img src={client} alt="" />
		</div>
	));

	return (
		<Banner id="clients" className="clients">
			<div className="col-md-8 mx-auto black">
				<div className="clients-desc">
					<h4 className="page-title">
						<FormattedMessage
							id="clients.title"
						/>
					</h4>
					<p>
						<FormattedMessage
							id="clients.description"
						/>
					</p>
				</div>
				{/* <Animation animate="fadeIn" once> */}
					<div className="row client-logo">
						{clientListDom}
					</div>
				{/* </Animation> */}
			</div>
		</Banner>
	);
}

ClientsComponent.propTypes = {
};

export default ClientsComponent;
