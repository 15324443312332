// webpack requires to use this file for import links for SEO
const serviceId = {
	PRODUCT_DISCOVERY: 'product-discovery',
	SOFTWARE_DELIVERY: 'software-delivery',
	AGILE_TRAINING: 'agile-training',
	AGILE_TRANSFORMATION: 'agile-transformation',
	BUSINESS_VALIDATION: 'business-validation',
	DESIGN_CAPABILITY: 'design-capability'
};

const articleId = {
	HARNESSING_GENERATIVE_AI: 'harnessing-generative-ai',
	LIGHT_BULB: 'light-bulb',
	PARTNER_WITH_SHARESHARE: 'partner-with-shareshare',
	DIGITAL_OUTREACH_PLATFORM: 'digital-outreach-platform',
	SNOWFLAKE: 'snowflake',
	UEX_CASE: 'uex-case'
};

const caseId = {
	GRAB_CASE: 'grab-case',
	UEX_CASE: 'uex-case'
};

const servicesParent = '/services';
const articlesParent = '/articles';

const paths = {
	HOME: '/',
	PRODUCT_DISCOVERY: `${servicesParent}/${serviceId.PRODUCT_DISCOVERY}`,
	SOFTWARE_DELIVERY: `${servicesParent}/${serviceId.SOFTWARE_DELIVERY}`,
	AGILE_TRAINING: `${servicesParent}/${serviceId.AGILE_TRAINING}`,
	AGILE_TRANSFORMATION: `${servicesParent}/${serviceId.AGILE_TRANSFORMATION}`,
	BUSINESS_VALIDATION: `${servicesParent}/${serviceId.BUSINESS_VALIDATION}`,
	DESIGN_CAPABILITY: `${servicesParent}/${serviceId.DESIGN_CAPABILITY}`,
	ARTICLES: articlesParent,
	HARNESSING_GENERATIVE_AI: `${articlesParent}/${articleId.HARNESSING_GENERATIVE_AI}`,
	LIGHTBULB: `${articlesParent}/${articleId.LIGHT_BULB}`,
	PARTNER_WITH_SHARESHARE: `${articlesParent}/${articleId.PARTNER_WITH_SHARESHARE}`,
	DIGITAL_OUTREACH_PLATFORM: `${articlesParent}/${articleId.DIGITAL_OUTREACH_PLATFORM}`,
	SNOWFLAKE: `${articlesParent}/${articleId.SNOWFLAKE}`,
	UEXCASE: `${articlesParent}/${articleId.UEX_CASE}`,
	ABOUT: '/about',
	JOBS: '/jobs',
	TERMS: '/terms',
	PRIVACY_POLICY: '/privacy-policy'
};

/* eslint-disable */
// Helper function to get all links for SEO
function getLinksArr() {
	let linksArr = [];
	for (let i in paths) {
		linksArr.push(paths[i]);
	};
	return linksArr;
};

export {
	serviceId,
	articleId,
	caseId,
	paths,
	getLinksArr
};
