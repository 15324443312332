import React from 'react';
import PropTypes from 'prop-types';

import Intro from '../components/Intro';
import Services from '../components/Services';
import Approach from '../components/Approach';
import Gallery from '../components/Gallery';
import Clients from '../components/Clients';
import Testimonials from '../components/Testimonials';

import ExtendNavbar from '../components/ExtendNavbar';
import DownArrow from '../components/DownArrow';

import gallery1 from '../assets/images/pict-1.jpg';
import gallery2 from '../assets/images/pict-2.jpg';
import gallery3 from '../assets/images/pict-3.jpg';
import gallery4 from '../assets/images/pict-4.jpg';
import gallery5 from '../assets/images/pict-5.jpg';

const galleries = [gallery1, gallery2, gallery3, gallery4, gallery5];

class HomePage extends React.Component {
	componentDidMount() {
		const { fetchServiceOfferings, fetchApproaches, fetchTestimonials } = this.props;

		fetchServiceOfferings();
		fetchApproaches();
		fetchTestimonials();
	}

	render() {
		const { serviceList, approachList, testimonialList } = this.props;
		return (
			<div className="home">
				{/* Intro */}
				<Intro />

				{/* Trigger Navbar Extension */}
				<ExtendNavbar />

				{/* Down Arrow */}
				<DownArrow />

				{/* Services */}
				<Services serviceList={serviceList} />

				{/* Approach */}
				<Approach approachList={approachList} />

				{/* Gallery */}
				<Gallery images={galleries} />

				{/* Clients */}
				<Clients />

				{/* Testimonials */}
				<Testimonials testimonialList={testimonialList} />
			</div>
		);
	}
}

HomePage.propTypes = {
	fetchServiceOfferings: PropTypes.func.isRequired,
	fetchApproaches: PropTypes.func.isRequired,
	fetchTestimonials: PropTypes.func.isRequired,
	serviceList: PropTypes.arrayOf(PropTypes.object).isRequired,
	approachList: PropTypes.arrayOf(PropTypes.object).isRequired,
	testimonialList: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default HomePage;
