import { articleId, paths } from '../../sources/constant';

import partnerWithShareShareHeaderImage from '../../assets/images/partnerWithShareShare/restaurant.jpg';
import anttiProfileImage from '../../assets/images/antti-thumbnail.jpg';

import shareshareImage from '../../assets/images/partnerWithShareShare/shareshare.jpg';
import restaurantAsiaImage from '../../assets/images/partnerWithShareShare/restaurant-asia.jpg';

export default {
	id: articleId.PARTNER_WITH_SHARESHARE,
	image: partnerWithShareShareHeaderImage,
	imageAlt: 'Partner With ShareShare',
	author: 'Dtal Technologies',
	authorImage: anttiProfileImage,
	date: '20 mar 2019',
	href: paths.PARTNER_WITH_SHARESHARE,
	en: {
		title: {
			first: 'Partner with',
			text: 'ShareShare',
			translate: false
		},
		thumbnailTitle: 'Partner with ShareShare',
		thumbnailDescription: 'Dtal is proud to join our partner ShareShare at the Restaurant Asia event at Marina Bay Sands.',
		content: [{
			html: ` <h4 class="page-title">Restaurant Asia 2019</h4>
					<p>
						<a href="/">Dtal</a> is proud to join our partner <a target="_blank" href="https://shareshare.app">ShareShare</a> at the <a target="_blank" href="http://restaurantasia.com.sg">Restaurant Asia</a> event at <a target="_blank" href="https://www.marinabaysands.com">Marina Bay Sands</a>.
					</p>
					<div class="row img-wrapper">
						<div class="col-12 col-lg-11 mx-auto">
							<p>
								<a target="_blank" href="https://shareshare.app">
									<img src=${shareshareImage} alt="ShareShare" class="img-fluid"/>
								</a>
							</p>
						</div>
					</div>
					<h5 class="subtitle">New Innovation in F&B Exhibited</h5>
					<p>Restaurant Asia is the 1st International Restaurant, Kitchen & Culinary Equipment Exhibition bringing together key players in the F&B sector to share and to be exposed to the latest trends, opportunities and developments. ShareShare is sponsoring the event and presenting unique referral marketing solution. With ShareShare F&B businesses can multiply their existing customer base through referrals and create new revenue. ShareShare offers result proof approach to increase business in a way that creates new opportunities for their customers at the same time. In the exhibition ShareShare is showcased in action or you can visit <a target="_blank" href="https://shareshare.app">shareshare.app</a> to find out more, download the app or register as a merchant.</p>
					<div class="col-12 col-lg-11 mx-auto">
							<p>
								<a target="_blank" href="http://restaurantasia.com.sg">
									<img src=${restaurantAsiaImage} alt="ShareShare" class="img-fluid"/>
								</a>
							</p>
						</div>
					<h5 class="subtitle">Technology empowers new opportunities</h5>
					<p>ShareShare is only possible through creative use of technology. Dtal team has worked hard together with ShareShare over the last months to create exciting user experience for users to learn more about merchants and to share with their friends, have a wallet with real monetary value to use or to withdraw. Apart from user application merchants have their own portal.</p>
					<h5 class="subtitle">How is this done?</h5>
					<p>Dtal works in very collaborative manner experimenting with our partners and with users throughout as the product is being built. The idea is turned into a product in a manner of weeks. Find out more about Dtal @ <a href="/">dtaltechnologies.com</a>.
					</p>`
		}]
	}
};
