const testimonialList = [
	{
		id: 1,
		name: 'Malcolm WONG',
		position: 'Deputy Executive Director',
		organisation: 'Rainbow Centre',
		content: 'It was a joy to work with a team that brought not just excellent facilitation skills to help us tease out the essentials we need from the system, but also clearly felt drawn to the work of helping enhance the education of persons with special needs.'
/*
	},
	{
		id: 2,
		name: 'Example Fello',
		position: 'Supreme Leader',
		organisation: 'Sarawak Association',
		content: 'Algebratek gave such great results. And always happy.'
*/
	}
];

export default testimonialList;
