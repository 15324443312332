import { articleId, paths } from '../../sources/constant';

import headerImage from '../../assets/images/digitalOutreachPlatform/dop-cover.jpg';
import anttiProfileImage from '../../assets/images/antti-thumbnail.jpg';

import app1Image from '../../assets/images/digitalOutreachPlatform/dop-app1.png';
import app2Image from '../../assets/images/digitalOutreachPlatform/dop-app2.png';
import app3Image from '../../assets/images/digitalOutreachPlatform/dop-app3.png';
import phoneDarkImage from '../../assets/images/digitalOutreachPlatform/phonedark-left.jpg';
import phoneLightImage from '../../assets/images/digitalOutreachPlatform/phonelight-right.jpg';

export default {
	id: articleId.DIGITAL_OUTREACH_PLATFORM,
	image: headerImage,
	imageAlt: 'Partner With ShareShare',
	author: 'Dtal Technologies',
	authorImage: anttiProfileImage,
	date: '13 jun 2019',
	href: paths.DIGITAL_OUTREACH_PLATFORM,
	en: {
		title: {
			first: 'Learning has',
			text: 'changed',
			translate: false
		},
		thumbnailTitle: 'Learning has changed - Introducing Digital Outreach Platform',
		thumbnailDescription: 'Everyone is a learner and we learn most of the time. No longer do we go to the classroom, but we learn on the go, at work, in groups and by ourselves.',
		content: [{
			html: ` <p>Everyone is a learner and we learn most of the time. No longer do we go to the classroom, but we learn on the go, at work, in groups and by ourselves.</p>
					<p>As learning practices change, content needs to be where our attention is. In our mobile device. Learning competes for our attention with enticing alternatives; therefore, it needs to be highly attractive, easy to consume, accessible and available.</p>

					<h5 class="subtitle">Introducing Digital Outreach Platform (DOP)</h5>
					<p>DOP makes digital content available in controlled way through mobile app – <b>branded for your business.</b> Protecting content, monetizing with subscriptions and with organizational groups.</p>
					<div class="row img-wrapper">
						<div class="col-12 col-md mx-auto">
							<img src=${app1Image} alt="Content view" class="img-fluid"/>
						</div>
						<div class="col-12 col-md mx-auto">
							<img src=${app2Image} alt="Media list" class="img-fluid"/>
						</div>
						<div class="col-12 col-md mx-auto">
							<img src=${app3Image} alt="Media player" class="img-fluid"/>
						</div>
						<p class="col-12">
							<small>DOP works with Android and IOS devices and can be fully customized for your needs. We can customize the front end for your liking while keeping the backend safe and secure with your content.</small>
						</p>
					</div>
					<div class="row img-wrapper">
						<div class="col-12 col-md mx-auto">
							<img src=${phoneDarkImage} alt="Dark theme" class="img-fluid"/>
						</div>
						<div class="col-12 col-md mx-auto">
							<img src=${phoneLightImage} alt="Light theme" class="img-fluid"/>
						</div>
						<p class="col-12">
							<small>DOP works just as well with dark or light themes.</small>
						</p>
					</div>

					<h5 class="subtitle">Tackling publishers’, enterprises’, training organizations’ and ministries’ challenges:</h5>
					<p>I have content on DVD and CD, but people use mobile devices. How can I distribute my materials?</p>
					<p>I can use YouTube, but how can I protect my unique content?</p>
					<p>How can I serve my customer in unique personalized way rather than be small fish in a big platform?</p>
					<p>How can I ensure that sensitive content is only visible to and accessed by targeted audience?</p>
					<p>Content and people always change, how can I manage distribution dynamically?</p>

					<h5 class="subtitle">Features:</h5>
					<ul>
						<li>Video content</li>
						<li>Audio content</li>
						<li>Learning materials</li>
						<li>Different resolutions</li>
						<li>Both streaming and local playback</li>
						<li>Content access protections</li>
						<li>Organizational content distribution</li>
						<li>Public content distribution</li>
						<li>Group and family handling</li>
						<li>Unlimited content plans</li>
						<li>Paid subscriptions</li>
						<li>Free tier</li>
						<li>Categorization of content</li>
						<li>Promoting content among users</li>
						<li>Reporting on content usage</li>
						<li>Session controls</li>
						<li>iOS and Android</li>
					</ul>
			`
		}]
	}
};
