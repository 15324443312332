import { serviceId } from '../sources/constant';

import image1 from '../assets/images/product-discovery.jpg';
import image2 from '../assets/images/software-delivery.jpg';
import image3 from '../assets/images/agile-training.jpg';
import image4 from '../assets/images/agile-transformation.jpg';
import image5 from '../assets/images/business-validation.jpg';
// import image6 from '@/assets/images/design-capability.jpg';

const serviceList = [{
	id: serviceId.PRODUCT_DISCOVERY,
	title: {
		first: 'serviceOffering.product',
		text: 'serviceOffering.discovery'
	},
	description: 'serviceOffering.productDiscoveryDescription',
	image: image1,
	imageAlt: 'Product Discovery',
	link: {
		text: 'serviceOffering.productDiscoverylink'
	}
}, {
	id: serviceId.SOFTWARE_DELIVERY,
	title: {
		first: 'serviceOffering.software',
		text: 'serviceOffering.delivery'
	},
	description: 'serviceOffering.softwareDeliveryDescription',
	image: image2,
	imageAlt: 'Software Delivery',
	link: {
		text: 'serviceOffering.softwareDeliverylink'
	}
}, {
	id: serviceId.AGILE_TRAINING,
	title: {
		first: 'serviceOffering.agile',
		text: 'serviceOffering.training'
	},
	description: 'serviceOffering.agileTrainingDescription',
	image: image3,
	imageAlt: 'Agile Training',
	link: {
		text: 'serviceOffering.agileTraininglink'
	}
}, {
	id: serviceId.AGILE_TRANSFORMATION,
	title: {
		first: 'serviceOffering.agile',
		text: 'serviceOffering.transformation'
	},
	description: 'serviceOffering.agileTransformationDescription',
	image: image4,
	imageAlt: 'Agile Transformation',
	link: {
		text: 'serviceOffering.agileTransformationlink'
	}
}, {
	id: serviceId.BUSINESS_VALIDATION,
	title: {
		first: 'serviceOffering.business',
		text: 'serviceOffering.validation'
	},
	description: 'serviceOffering.businessValidationDescription',
	image: image5,
	imageAlt: 'Business Validation',
	link: {
		text: 'serviceOffering.businessValidationlink'
	}
/*
}, {
	id: serviceId.DESIGN_CAPABILITY,
	title: {
		first: 'serviceOffering.design',
		text: 'serviceOffering.capability'
	},
	description: 'serviceOffering.designCapabilityDescription',
	image: image6,
	imageAlt: 'Design Capability',
	link: {
		text: 'serviceOffering.designCapabilitylink'
	}
	*/
}];

export default serviceList;
